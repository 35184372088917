import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';

import mediaqueries from '@styles/media';
import { IArticle } from '@types';

import { GridLayoutContext } from './Articles.List.Context';
import ListItem from './List.Item';

interface ArticlesListProps {
  articles: IArticle[];
  alwaysShowAllDetails?: boolean;
}

const ArticlesList: React.FC<ArticlesListProps> = ({
  articles,
  alwaysShowAllDetails,
}) => {
  if (!articles) return null;

  const hasOnlyOneArticle = articles.length === 1;
  const { gridLayout = 'tiles', hasSetGridLayout, getGridLayout } = useContext(
    GridLayoutContext,
  );

  /**
   * We're taking the flat array of articles [{}, {}, {}...]
   * and turning it into an array of pairs of articles [[{}, {}], [{}, {}], [{}, {}]...]
   * This makes it simpler to create the grid we want
   */
  const articlePairs = articles.reduce((result, value, index, array) => {
    if (index % 2 === 0) {
      result.push(array.slice(index, index + 2));
    }
    return result;
  }, []);

  useEffect(() => getGridLayout(), []);

  return (
    <ArticlesListContainer
      style={{ opacity: hasSetGridLayout ? 1 : 0 }}
      alwaysShowAllDetails={alwaysShowAllDetails}
    >
      <List>
        {articles.map((ap, index) => {
          return (
            <ListItem key={index} article={ap} squareLayout={true}/>
          );
        })}
      </List>
    </ArticlesListContainer>
  );
};

export default ArticlesList;

const ArticlesListContainer = styled.div<{ alwaysShowAllDetails?: boolean }>`
  transition: opacity 0.25s;
`;

const List = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
  
  &:not(:last-child) {
    margin-bottom: 75px;
  }
  
  ${mediaqueries.desktop_giant`
    grid-template-columns: 1fr 1fr 1fr;
  `}

  ${mediaqueries.desktop_large`
    grid-template-columns: 1fr 1fr 1fr;
  `}

  ${mediaqueries.desktop_medium`
    grid-template-columns: 1fr 1fr 1fr;
  `}

  ${mediaqueries.tablet`
    grid-template-columns: 1fr 1fr;
    column-gap: 2px;
    row-gap: 2px;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  `}
`;
