import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import Section from '@components/Section';
import Headings from "@components/Headings";
import mediaqueries from '@styles/media';
import { IAuthor } from '@types';

const authorQuery = graphql`
  {
    site: allSite {
      edges {
        node {
          siteMetadata {
            hero {
              featuredArticlesHeading
              featuredArticlesSubtitle
              maxWidth
            }
          }
        }
      }
    }
  }
`;

const FeaturedArticlesHero: React.FC<IAuthor> = ({ counter }) => {

  const results = useStaticQuery(authorQuery);
  const hero = results.site.edges[0].node.siteMetadata.hero;

  return (
    <Section id="Articles__Hero">
      <HeadingContainer style={{ maxWidth: `${hero.maxWidth}px` }}>
        <Headings.h1>
          {hero.featuredArticlesHeading}
          <Sup>{counter + " lessons"}</Sup>
        </Headings.h1>
        <InfoText>
          {hero.featuredArticlesSubtitle}
        </InfoText>
      </HeadingContainer>
    </Section>
  );
};

export default FeaturedArticlesHero;

const HeadingContainer = styled.div`
  margin: 48px 0 80px;
  
  ${mediaqueries.desktop`
    width: 80%;
  `}
  
  ${mediaqueries.tablet`
    width: 100%;
  `}
  `;

const InfoText = styled.h3`
  font-size: 22px;
  line-height: 36px;
  margin-top: 16px;
  font-family: ${p => p.theme.fonts.body};
  font-weight: ${p => p.theme.fontsWeight.regular};
  color: ${p => p.theme.colors.secondary};
`;

const Sup = styled.sup`
  font-size: 24px;
  margin-left: 8px;
  width: 100%;
  ${p => p.theme.textGradient};
  vertical-align: text-top;
  letter-spacing: -1px;
  font-family: ${p => p.theme.fonts.body};
  font-weight: ${p => p.theme.fontsWeight.regular};
`;
